@import "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --clr-foreground: 0, 0%, 20%;
  --clr-background: 0, 0%, 100%;
  --clr-accent: 159, 70%, 53%;
  --clr-border: 0, 0%, 90%;
  --clr-muted: 0, 0%, 15%;
  --clr-overlay: 0, 0%, 0%, .25;
  --clr-shadow: 0, 0%, 50%;
  --clr-link: 159, 70%, 53%;
  --clr-hover: 159, 70%, 63%;
  --clr-lc-easy: 180, 74%, 42%;
  --clr-lc-medium: 43, 100%, 50%;
  --clr-lc-hard: 0, 91%, 59%;
  --spacing-base: 1rem;
  --spacing-1: calc(var(--spacing-base) * 1.618);
  --spacing-2: calc(var(--spacing-1) * 1.618);
  --spacing-3: calc(var(--spacing-2) * 1.618);
  --spacing-4: calc(var(--spacing-3) * 1.618);
  --spacing-5: calc(var(--spacing-4) * 1.618);
  --radius-1: .25rem;
  --radius-2: calc(var(--radius-1) * 1.618);
  --radius-3: calc(var(--radius-2) * 1.618);
  --radius-4: calc(var(--radius-3) * 1.618);
  --radius-5: calc(var(--radius-4) * 1.618);
  --font-size-base: 1rem;
  --font-size-lg: calc(var(--font-size-base) * 1.618);
  --font-size-xl: calc(var(--font-size-lg) * 1.618);
  --font-size-xxl: calc(var(--font-size-xl) * 1.618);
  --font-size-huge: calc(var(--font-size-xxl) * 1.618);
  --font-size-small: calc(var(--font-size-base) / 1.618);
  --z-idx-0: 1;
  --z-idx-1: 10;
  --z-idx-2: 100;
  --z-idx-3: 1000;
  --z-idx-4: 10000;
  --z-idx-5: 100000;
  --shadow-light: 0 2px 5px hsla(var(--clr-shadow), .1);
  --shadow-medium: 0 6px 15px hsla(var(--clr-shadow), .2);
  --shadow-heavy: 0 15px 30px hsla(var(--clr-shadow), .3);
  --blur-sm: blur(2px);
  --blur-md: blur(4px);
  --blur-lg: blur(8px);
  --bg-semi-transparent: hsla(var(--clr-foreground), .02);
  --max-width: 1280px;
}

[data-theme="dark"] {
  --clr-background: 0, 0%, 5%;
  --clr-foreground: 0, 0%, 100%;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  background: hsl(var(--clr-accent), .1);
  color: hsl(var(--clr-accent));
}

html {
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
  font-size: 16px;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd, ul, ol, li {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  list-style-type: none;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin-block-end: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: Inter, sans-serif;
  line-height: 1.6;
  font-size: var(--font-size-base);
  background: hsl(var(--clr-background));
  color: hsl(var(--clr-foreground));
  transition: background .75s, color .75s;
}

#root {
  width: 100%;
  max-width: var(--max-width);
  padding-inline: var(--spacing-1);
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  margin-block: .5rem;
  line-height: 1.1;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font-family: inherit;
  font-size: inherit;
}

button, input[type="button"], input[type="submit"] {
  cursor: pointer;
  background: none;
  border: none;
}

textarea:not([rows]) {
  min-height: 10em;
}

:target {
  scroll-margin-block: 5ex;
}

.font--inter {
  font-optical-sizing: auto;
  font-family: Inter, sans-serif;
  font-style: normal;
}

.font--sour-gummy {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-family: Sour Gummy, sans-serif;
  font-style: normal;
}

.font-weight--100 {
  font-weight: 100;
}

.font-weight--200 {
  font-weight: 200;
}

.font-weight--300 {
  font-weight: 300;
}

.font-weight--400 {
  font-weight: 400;
}

.font-weight--500 {
  font-weight: 500;
}

.font-weight--600 {
  font-weight: 600;
}

.font-weight--700 {
  font-weight: 700;
}

.font-weight--800 {
  font-weight: 800;
}

.font-weight--900 {
  font-weight: 900;
}

.lucide-icon {
  padding-top: 2px;
}
/*# sourceMappingURL=index.0029e196.css.map */
