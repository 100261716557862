@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Sour+Gummy:ital,wght@0,100..900;1,100..900&display=swap");

/* Dark and Light Theme Variables */
:root {
  --clr-foreground: 0, 0%, 20%; /* Dark gray for text */
  --clr-background: 0, 0%, 100%; /* White background */

  --clr-accent: 159, 70%, 53%; /* NeonGreen-accent color for highlights */

  --clr-border: 0, 0%, 90%; /* Light gray borders */
  --clr-muted: 0, 0%, 15%; /* Muted text for secondary elements */
  --clr-overlay: 0, 0%, 0%, 0.25; /* Dark overlay for modals, etc. */
  --clr-shadow: 0, 0%, 50%; /* Soft shadow color */
  --clr-link: 159, 70%, 53%; /* Accent color for links */
  --clr-hover: 159, 70%, 63%; /* Hover state for links */

  --clr-lc-easy: 180, 74%, 42%; /* Status: Leetcode Easy Difficulty */
  --clr-lc-medium: 43, 100%, 50%; /* Status: Leetcode Medium Difficulty */
  --clr-lc-hard: 0, 91%, 59%; /* Status: Leetcode Hard Difficulty */

  --spacing-base: 1rem; /* 1rem as the base unit */
  --spacing-1: calc(var(--spacing-base) * 1.618); /* 1.618rem */
  --spacing-2: calc(var(--spacing-1) * 1.618); /* 2.618rem */
  --spacing-3: calc(var(--spacing-2) * 1.618); /* 4.236rem */
  --spacing-4: calc(var(--spacing-3) * 1.618); /* 6.854rem */
  --spacing-5: calc(var(--spacing-4) * 1.618); /* 11.08rem */

  /* Border Radius (rounded corners) */
  --radius-1: 0.25rem;
  --radius-2: calc(var(--radius-1) * 1.618); /* 0.4rem */
  --radius-3: calc(var(--radius-2) * 1.618); /* 0.65rem */
  --radius-4: calc(var(--radius-3) * 1.618); /* 1.05rem */
  --radius-5: calc(var(--radius-4) * 1.618); /* 1.7rem */

  /* Font Sizes (Golden Ratio) */
  --font-size-base: 1rem; /* 1rem (16px) as base size */
  --font-size-lg: calc(var(--font-size-base) * 1.618); /* 1.618rem (26px) */
  --font-size-xl: calc(var(--font-size-lg) * 1.618); /* 2.618rem (42px) */
  --font-size-xxl: calc(var(--font-size-xl) * 1.618); /* 4.236rem (68px) */
  --font-size-huge: calc(var(--font-size-xxl) * 1.618); /* 6.854rem (110px) */
  --font-size-small: calc(var(--font-size-base) / 1.618); /* 0.618rem (10px) */

  /* ZIndex */
  --z-idx-0: 1;
  --z-idx-1: 10;
  --z-idx-2: 100;
  --z-idx-3: 1000;
  --z-idx-4: 10000;
  --z-idx-5: 100000;

  /* Shadows */
  --shadow-light: 0 2px 5px hsla(var(--clr-shadow), 0.1);
  --shadow-medium: 0 6px 15px hsla(var(--clr-shadow), 0.2);
  --shadow-heavy: 0 15px 30px hsla(var(--clr-shadow), 0.3);

  /* Blur */
  --blur-sm: blur(2px);
  --blur-md: blur(4px);
  --blur-lg: blur(8px);

  /* Default Styles*/
  --bg-semi-transparent: hsla(var(--clr-foreground), 0.02);
  --max-width: 1280px;
}

[data-theme="dark"] {
  --clr-background: 0, 0%, 5%;
  --clr-foreground: 0, 0%, 100%;
}

/* Global Reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Default Selection Element Reset to Accent */
*::selection {
  background: hsl(var(--clr-accent), 0.1);
  color: hsl(var(--clr-accent));
}

/* Prevent font size inflation */
html {
  height: 100%;

  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  font-size: 16px; /* Base font size for better scaling */
}

/* Set core body defaults */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  list-style-type: none;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Remove default margins from all block-level elements */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

/* Apply box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100%;

  line-height: 1.6;
  font-family: "Inter", sans-serif;
  font-size: var(--font-size-base);

  background: hsl(var(--clr-background));
  color: hsl(var(--clr-foreground));

  transition: background 0.75s ease, color 0.75s ease;
}

#root {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;

  padding-inline: var(--spacing-1);
}

/* Set headings with proper line heights */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  margin-block: 0.5rem;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

/* Links reset */
a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:focus {
  text-decoration: underline;
}

/* Images */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Form elements */
input,
button,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
}

button,
input[type="button"],
input[type="submit"] {
  background: none;
  border: none;
  cursor: pointer;
}

textarea:not([rows]) {
  min-height: 10em;
}

/* Set up scroll margin for anchor targets */
:target {
  scroll-margin-block: 5ex;
}

/* Fonts */
.font--inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.font--sour-gummy {
  font-family: "Sour Gummy", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

/* Font Weights */
.font-weight--100 {
  font-weight: 100;
}
.font-weight--200 {
  font-weight: 200;
}
.font-weight--300 {
  font-weight: 300;
}
.font-weight--400 {
  font-weight: 400;
}
.font-weight--500 {
  font-weight: 500;
}
.font-weight--600 {
  font-weight: 600;
}
.font-weight--700 {
  font-weight: 700;
}
.font-weight--800 {
  font-weight: 800;
}
.font-weight--900 {
  font-weight: 900;
}

/* Global Styles */
.lucide-icon {
  padding-top: 2px;
}

/* Responsiveness */
/* Small devices (Phones, less than 768px) */
@media screen and (max-width: 767px) {
  /* Styles for small screens */
}

/* Tablets (768px and above) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Styles for tablet-sized screens */
}

/* Laptops/Desktops (1024px and above) */
@media screen and (min-width: 1024px) {
  /* Styles for desktops */
}

/* Large Desktops (1440px and above) */
@media screen and (min-width: 1440px) {
  /* Styles for large desktop screens */
}
